<template>
  <div class="container-fixed">
    <Navigation/>
    <div class="portfolio-client">
      <div class="proj-links">
        <ul class="">
          <li v-for="cl in currentClients" :key="cl.id" :class="cl.id == client.id ? 'selected':''">
            <a :href="'/portfolio/' + cl.id">{{cl.name}}</a>
          </li>
        </ul>
        <b-pagination v-model="currentPage" :total-rows="clients.length" :per-page="perPage" align="center" hide-goto-end-buttons></b-pagination>
      </div>
      <div class="content-img animate__animated animate__fadeInDown" :style="{backgroundImage: 'url('+client.image_url+')'}"></div>
      <div class="proj-container">
        <ul class="proj-nav">
          <li v-for="category in categories" :key="category.id" :class="category.id == client.main_cat ? 'selected':''">
            <a :href="getCategoryFirstClient(category.id)">{{category.name}}</a>
          </li>
        </ul>
        <a class="proj-nav-link" href="/portfolio">
          <img src="/assets/icons/feather-arrow-left-blue.png" alt="">
          Project List
        </a>
        <div class="proj-content-body">
          <div class="content-text">
            <div class='animate__animated animate__fadeInLeft'>
            <h2>Our Works</h2>
            <div class="divider"><hr></div>
            </div>
            <div class="content">
              <div class="client-nav">
                <a class="proj-nav-link" :href="prevClientUrl">
                  <img src="/assets/icons/chevron-left-blue.png" alt="">
                </a>
                <a class="proj-nav-link" :href="nextClientUrl">
                  <img src="/assets/icons/chevron-right-blue.png" alt="">
                </a>
              </div>
              <div v-for="logo of client.logoFiles" :key="logo.id" class="client-logo animate__animated animate__fadeInDown" :style="{backgroundImage: 'url('+logo.url+')'}"></div>
              <div class="client-description-head animate__animated animate__fadeInDown animate__delay-1s">프로젝트 개요</div>
              <div class="client-description animate__animated animate__fadeInDown animate__delay-1s" v-html="client.description"></div>
            </div>
          </div>
        </div>
        <div class="client-table-container animate__animated animate__fadeInDown">
          <div class="client-table">
            <div class="client-name">
              <div class="client-caption">Client</div>
              <div>{{client.name}}</div>
              <div>{{client.subname}}</div>
            </div>
            <div class="client-industries">
              <div class="client-caption">Industry</div>
              <div>{{industries[0]}}</div>
              <div>{{industries[1]}}</div>
              <div>{{industries[2]}}</div>
            </div>
            <div class="client-sectors">
              <div class="client-caption">Sector</div>
              <div>{{sectors[0]}}</div>
              <div>{{sectors[1]}}</div>
              <div>{{sectors[2]}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import 'bootstrap/dist/css/bootstrap.css'
import store from '@/store'
import api from '@/api'
import { BPagination } from 'bootstrap-vue'

export default {
  components: {
    Navigation: () => import('@/components/nav'),
    BPagination
  },
  props: {
    id: {
      type: [String, Number],
      default: 0
    }
  },
  data: () => ({
    currentPage: 1,
    perPage: 12,
    categories: [],
    clients: [],
    pageCount: 1,
    client: {
      id: 0,
      sectors: [],
      industries: [],
      main_cat: 0
    },
    industries: [],
    sectors: [],
    prevClientUrl: '#',
    nextClientUrl: '#'
  }),
  computed: {
    currentClients: function () {
      var cs = [];
      var start = (this.currentPage - 1) * this.perPage;
      var last = start + this.perPage;
      var end = Math.min(this.clients.length, last);
      for (var ctr = start; ctr < end; ctr++) {
        cs.push(this.clients[ctr]);
      }
      return cs;
    }
  },
  async beforeMount() {
    await api.portfolio.getClientCategories()
    await api.portfolio.getClients()
    await api.portfolio.getClientSectors()
    await api.portfolio.getClientIndustries()

    this.categories = store.state.portfolio.categories;

    //client
    for (var c of store.state.portfolio.clients) {
      if (c.id == this.id) {
        this.client = c;
        break;
      }
    }
    //industries
    for (var industry of store.state.portfolio.industries) {
      if (this.client.industries.includes(industry.id)) {
        this.industries.push(industry.name)
      }
    }
    //sectors
    for (var sector of store.state.portfolio.sectors) {
      if (this.client.sectors.includes(sector.id)) {
        this.sectors.push(sector.name)
      }
    }

    //clients
    for (let client of store.state.portfolio.clients) {
      if (client.categories.includes(this.client.main_cat)) {
        this.clients.push(client);
      }
    }
    //pageCount
    if (this.clients.length > this.perPage) {
      this.pageCount = Math.ceil(this.clients.length / this.perPage)
    }

    //prevClientUrl
    var prevC = {}
    for (var cp of this.clients) {
      if (cp.id == this.id) {
        this.prevClientUrl = `/portfolio/${prevC.id}`;
        break;
      }
      prevC = cp
    }
    //nextClientUrl
    var prevClientIDs = []
    for (var cn of this.clients) {
      prevClientIDs.push(cn.id)
      if (cn.id == this.id) {
        break;
      }
    }
    for (var cl of this.clients) {
      if (!prevClientIDs.includes(cl.id)) {
        this.nextClientUrl = `/portfolio/${cl.id}`;
        break;
      }
    }

    var self = this;
    setTimeout(function () {
      var ctr = 0;
      for (ctr = 0; ctr < self.clients.length; ctr++) {
        if (self.clients[ctr].id == self.id) {
          break;
        }
      }
      ctr += 1;
      self.currentPage = Math.ceil(ctr / self.perPage);
    }, 10);
  },
  mounted() {
    store.commit('SET_NAV_PAGE', 'portfolio')
    store.commit('SET_NAV_THEME', 'dark')
    store.commit('SET_MORE_THEME', 'dark')
  },
  methods: {
    getCategoryFirstClient: function (cat_id) {
      for (let client of store.state.portfolio.clients) {
        if (client.categories.includes(cat_id)) {
          return '/portfolio/' + client.id;
        }
      }
    }
  }
}
</script>
<style>
div.client-description ul {
  padding-left: 24px;
}
</style>
<style scoped>
.proj-nav-link,
.client-nav {
  display: none;
}
.portfolio-client {
  padding: 24px;
  margin: 75px auto 0;
  display: flex;
  width: 100%;
  max-width: 1600px;
  height: calc(100% - 75px);
}

ul.proj-nav {
  display: flex;
  padding-left: 0;
  list-style-type: none;
  margin: 0;
}
ul.proj-nav li {
  border-right: 1px solid #cfcfcf;
  padding: 0 24px;
}
ul.proj-nav li:first-child {
  padding-left: 0;
}
ul.proj-nav li:last-child {
  border-right: none;
}
ul.proj-nav li a {
  color: #cfcfcf !important;
  text-decoration: none;
  list-style: none;
  font-size: 14px;
  font-weight: bold;
}
ul.proj-nav li a:hover,
ul.proj-nav li.selected a {
  color: #008ED6 !important;
}
.proj-container {
  flex: 0 0 85%;
  padding: 24px;
  position: relative;
}
.proj-links {
  flex: 0 0 15%;
  border-right: 1px solid #cfcfcf;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.proj-links ul {
  padding: 0;
  list-style-type: none;
}
.proj-links ul li {

}
.proj-links ul li:hover {
  color: #008ED6;
}
.proj-links ul li.selected {
  color: #008ED6;
  border-right: 5px solid #008ED6;
  margin-right: -3px;
}
.proj-links ul li a {
  display: block;
  text-decoration: none;
  color: #CFCFCF;
  font-weight: bold;
  line-height: 3;
  font-family: 'Montserrat';
}
.proj-links ul li a:hover  {
  text-decoration: none;
  color: #008ED6;
  font-weight: bold;
  font-family: 'Montserrat';
}
.proj-links ul li.selected a {
  color: #008ED6;
}
.proj-content-body {
  height: calc(100% - 100px - 21px);
  display: flex;
}

.proj-links ul.b-pagination {
  margin-right: 24px;
}

div.content-text {
  width: 36%;
  margin-top: 64px;
}
div.content-text h2 {
  font-family: 'Montserrat';
  color: #008ED6;
  font-size: 45px;
  margin-bottom: 0;
}
div.divider {
  width: 100px;
  padding: 0 10px;
  margin: 24px 0;
}
div.divider hr {
  background: #F1B500;
  height: 5px;
  border: none;
  width: 100%;
  margin-right: auto;
}
div.content-img {
  position: fixed;
  right: 0;
  top: 10rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 50%;
  height: calc(100% - 180px);
}

div.client-logo {
  width: 100%;
  height: 100px;
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
}

div.client-description-head {
  color: #959595;
  font-weight: bold;
  font-size: 18px;
  margin-top: 24px;
}
div.client-description {
  color: #959595;
  font-size: 20px;
}

div.client-table-container {
  background: #008ED6;
  color: white;
  position: absolute;
  width: 60%;
  bottom: -24px;
  left: 0;
}
div.client-table {
  width: calc(80% - 48px);
  min-height: 120px;
  margin: auto;
  padding: 24px;
  display: flex;
}
div.client-table > div {
  flex: 0 0 33%;
}

div.client-caption {
  font-size: 14px;
  font-family: "Roboto";
  font-weight: lighter;
  margin-bottom: 12px;
}
div.client-table > div > div:not(.client-caption) {
  font-size: 22px;
  font-family: "Roboto";
  font-weight: bold;
}

@media(max-width: 1280px) {
  div.content-text h2 {
    font-size: 36px;
  }
  div.client-table-container {
    width: 75%;
  }
  div.client-table {
    width: calc(85% - 48px);
  }
  div.content-text {
    width: 45%;
    margin-top: 48px;
  }
  div.content-img {
    width: 50%;
  }
  div.client-description {
    width: 80%;
  }
}
@media(max-width: 1024px) {
  div.content-text h2 {
    font-size: 30px;
  }
  .proj-links ul li a {
    font-size: 12px;
  }
}

@media(max-width: 768px) {
  .proj-links,
  ul.proj-nav {
    display: none;
  }
  .proj-nav-link {
    display: block;
    text-align: right;
  }
  .client-nav {
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
    margin-bottom: -72px;
  }
  div.content-img {
    width: 100%;
    height: 100px;
    top: 0;
  }
  .portfolio-client {
    flex-direction: column;
    height: calc(100% - 64px);
  }
  .proj-container {
    flex-basis: 100%;
    margin: 0 -24px -24px -24px;
  }
  div.content-text {
    margin-top: 24px;
    width: 100%;
  }
  div.client-logo {
    max-width: 256px;
    margin: auto;
    background-position: center;
  }
  div.client-table-container {
    width: 100%;
    bottom: -12px;
  }
  div.client-table {
    width: 100%;
    padding: 24px 12px;
  }
}

@media(max-height: 680px) {
  .proj-container {
    padding: 12px 24px;
  }
  .proj-nav-link {
    font-size: 12px;
  }
  div.content-text h2 {
    font-size: 20px;
  }
  div.divider {
    margin: 12px 0;
  }
  div.client-logo {
    height: 64px;
  }
  div.client-description-head {
    font-size: 12px;
  }
  div.client-description {
    font-size: 12px;
  }
  div.client-table {
    padding: 12px;
  }
  div.client-caption {
    font-size: 12px;
    margin-bottom: 6px;
  }
  div.client-table>div>div:not(.client-caption) {
    font-size: 12px;
  }
}
</style>

<style>
@media(max-width: 1024px) {
  #plusfriend-chat-button {
    bottom: 12px;
  }
}

@media(max-width: 768px) {
  #plusfriend-chat-button {
    bottom: 108px;
  }
}
</style>
